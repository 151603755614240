
<template>
  <div class="cloudStorage">
    <div class="echars-box">
      <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
      <div id="myChart" style="width: 600px;height:400px;"></div>
      <!-- ============================================================== -->
      <div id="main" style="width: 450px;height:300px;"></div>
    </div>
    <div class="shuoming"> <span @click="to_shuming">云盘说明</span></span> </div>
  </div>
</template>

<script>

// 
import { query_ent_net_disk_info } from '../../api/store'


// 引入基本模板
const echarts = require('echarts/lib/echarts')
// 引入饼图组件
require('echarts/lib/chart/pie')
// 引入提示框和title组件// 引入提示框、标题组件、图例
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/graphic')
require('echarts/lib/component/legend')



export default {
  data() {
    return {
      switchover: true,
      msg: 'Welcome to Your Vue.js App',
      data_new :{},
    }
  },
   mounted() {
    this.query_ent_net_disk_info()
  },
  methods: {
    toswitchover() {
      this.switchover = !this.switchover
    },
    drawLine() {
      let data_new = this.data_new
      console.log(data_new);
      let size_total = ( (data_new.size_total*1)/1024/1024 ).toFixed(2) // 单位G
      let size_used = (  (data_new.size_used*1)/1024/1024 ).toFixed(2) // 已用 单位G
      let size_unused = ( (data_new.size_unused*1)/1024/1024 ).toFixed(2) //未使用

      let rate_size_used = data_new.rate_size_used // 已使用的企业 百分比
      let rate_size_unused = data_new.rate_size_unused // 未使用 百分比

      console.log(data_new.size_total,size_total);
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'))
      // 指定图表的配置项和数据
      const option1 = {
        // 提示框，鼠标悬浮交互时的信息提示
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
          // legend: {
          //   // show: false,
          //   orient: 'vertical',
          //   // left: 10,
          //   x: 'left',
          //   data: ['学生', '老师']
          // },
        // 图例属性，以饼状图为例，用来说明饼状图每个扇区，data与下边series中data相匹配
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: `${size_used}/${size_total}G`, //`/${(data_new.size_total*1/1024/1024)}`, //'30G/100G', // 使用“+”可以使每行文字居中
            textAlign: 'center',
            // font: 'italic bolder 16px cursive',
            fontSize: 18,
            fill: '#333333',
            width: 30,
            height: 30
          }
        },
        // 此例饼状图为圆环中心文字显示属性，这是一个原生图形元素组件，功能很多
        series: [{
          // tooltip提示框中显示内容
          name: '使用情况',
          // 图形类型，如饼状图，柱状图等
          type: 'pie',
          center: ['50%', '50%'],
          // 饼图的半径，数组的第一项是内半径，第二项是外半径。支持百分比，本例设置成环形图。具体可以看文档或改变其值试一试
          radius: ['35%', '65%'],
          // roseType:'area',是否显示成南丁格尔图，默认false
          itemStyle: {
            normal: {
              label: {
                show: true,
                textStyle: {
                  color: '#3c4858',
                  fontSize: '18'
                  // fontSize: '30',
                  // fontWeight: 'bold'
                },
                formatter: function(val) { // 让series 中的文字进行换行
                  return val.name.split('-').join('\n')
                }
              },
              // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等。可以与itemStyle属性同级，具体看文档
              labelLine: {
                show: true,
                lineStyle: {
                  // color: '#3c4858'
                  color: 'red'
                }
              }
              // 线条颜色
            },
            // 基本样式
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              // 鼠标放在区域边框颜色
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              textColor: '#000'
              // 鼠标放在各个区域的样式
            }
          },
          data: [{
            value: rate_size_used,
            name: `已用${rate_size_used*100}%-(${size_used*1}G ,${rate_size_used*100}%  )`
          },
          {
            value: rate_size_unused,
            // name: '70%-(70G,70%)'
            name: `剩余${rate_size_unused*100}%-(${size_unused*1}G ,${rate_size_unused*100}%  )`
          }
          ],
          // 数据，数据中其他属性，查阅文档
          // 各个区域颜色
          color: ['#FF404E', '#E5E5E5']
        } // 数组中一个{}元素，一个图，以此可以做出环形图
        ] // 系列列表
      }
      // 绘制图表
      myChart.setOption(option1)
    },
    drawLine2() {

      let data_new = this.data_new

      let size_used_ent = ( (data_new.size_used_ent*1)/1024/1024 ).toFixed(2) // 已使用G 企业
      let size_used_per = ( (data_new.size_used_per*1)/1024/1024 ).toFixed(2) // 已使用G 个人

      let rate_size_used_per = data_new.rate_size_used_per //  已使用G 个人 百分比
      let rate_size_used_ent  = data_new.rate_size_used_ent // 已使用G 企业 百分比

console.log('=====================',rate_size_used_per,rate_size_used_ent);

      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('main'))
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [{
          name: '已使用内存分布',
          type: 'pie',
          center: ['60%', '50%'],
          radius: [0, '60%'],
          label: {
            normal: {
              color: '#ffffff',
              position: 'inner'
            }
          }, // 扇形区域内显示文字
          data: [{
            value: rate_size_used_per,
            name: '个人\n' +  `( ${size_used_per}G,${rate_size_used_per*100}% )` //'(20G.60%)'
          },
          {
            value: rate_size_used_ent,
            name: '企业\n' + `( ${size_used_ent}G,${rate_size_used_ent*100}% )`  //'(10G,30%)'
          }
          ],
          color: ['#5F9CFE', '#FFA453']
        },
        // {
        //   name: '角色分类统计',
        //   type: 'pie',
        //   radius: ['35%', '75%'],
        //   labelLine: {
        //     normal: {
        //       show: true
        //     }
        //   }
        // }
        ]
      }
      // 绘制图表
      myChart.setOption(option)
    },

    to_shuming(){
        this.$router.push({
          path: '/shuoming'
        })
    },

    query_ent_net_disk_info(){
      query_ent_net_disk_info({data:{ent_id:this.$store.state.ent_id,}}).then((res)=>{
        console.log(res)
        if (res.code == '200') {
          this.data_new = res.body.data
          this.drawLine()
          this.drawLine2()
        }
      })
    },

  }

}
</script>

<style scoed lang="scss">
.title-btn {
  // width: 100%;
  height: 60px;
  font-size: 18px;
  span {
    margin: 10px 30px;
    padding: 10px 20px;
    border: 1px solid #cccccc;
    border-radius: 12px;
  }
  .titleBtn-colorY {
    color: #ffffff;
    background-color: #ff404e;
  }
  .titleBtn-color {
    color: #ff404e;
    background-color: #ffffff;
  }
}
.echars-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shuoming{
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  > span{
    display: block;
    margin: 0 0 0 100px;
    color: #ff404e;
  }
}
</style>
